<script lang="ts" setup>
const enter = (element: any) => {
  const height = getComputedStyle(element).height;

  element.style.height = height;
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.width = "auto";

  const width = getComputedStyle(element).width;

  element.style.height = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.width = 0;

  getComputedStyle(element).width;

  requestAnimationFrame(() => {
    element.style.width = width;
  });
};

const afterEnter = (element: any) => {
  element.style.width = "auto";
};

const leave = (element: any) => {
  const width = getComputedStyle(element).width;

  element.style.width = width;

  getComputedStyle(element).width;

  requestAnimationFrame(() => {
    element.style.width = 0;
  });
};
</script>

<template>
  <Transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </Transition>
</template>

<style scoped>
.expand-enter-to,
.expand-leave-from {
  opacity: 1;
}

.expand-enter-active,
.expand-leave-active {
  transition: all 250ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  width: 0;
}
</style>
